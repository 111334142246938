function getDateString(date) {
  var spl = date.split('-');
  const months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
  };
  return months[spl[1]] + " " + parseInt(spl[2]) + ", " + spl[0];

}

export default getDateString
